<template>
  <v-dialog v-model="dialog" width="350">
    <template v-slot:activator="{}">
      <v-btn dense height="30" block elevation="0" @click="open">Editar</v-btn>
    </template>
    <v-card>
      <v-card-title>Inventario</v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="inventory-configuration-form"
        @submit.prevent="validateForm()"
      >
        <v-simple-table>
          <thead>
            <tr>
              <th width="200">Producto</th>
              <th width="100">Cantidad</th>
              <th width="50"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(inv, i) in inventories" :key="i">
              <td>
                <v-select
                  :items="items"
                  outlined
                  dense
                  hide-details
                  style="max-width: 150px"
                  v-model="inv.inventory_id"
                  item-value="id"
                  :item-text="(x) => `${x.product_cody} - ${x.product_name}`"
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  :error-messages="
                    errors.collect('inventory-configuration-form.inventory_id')
                  "
                  data-vv-name="inventory_id"
                ></v-select>
              </td>
              <td>
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="inv.quantity"
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  :error-messages="
                    errors.collect('inventory-configuration-form.quantity')
                  "
                  data-vv-name="quantity"
                ></v-text-field>
              </td>
              <td>
                <v-btn icon @click="del(i)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2" class="text-center">
                <v-btn icon @click="add">
                  <v-icon size="36">mdi-plus-circle</v-icon>
                </v-btn>
              </td>
            </tr>
          </tfoot>
        </v-simple-table>
        <v-card-actions>
          <v-spacer />

          <v-btn
            outlined
            @click="
              dialog = false;
              files = [];
            "
            style="height: 25px; width: 100px"
            elevation="0"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            type="submit"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            elevation="0"
          >
            <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: $t("") }) }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "",
  props: ["sub_service"],
  data() {
    return {
      dialog: false,
      inventories: [],
      items: [],
    };
  },
  methods: {
    ...mapActions("subservices", ["inventorySubService"]),
    async validateForm() {
      this.errors.clear();
      let result = await this.$validator.validateAll(
        "inventory-configuration-form"
      );
      if (result) {
        console.log("VALIDATED");
        this.inventorySubService({
          service_id: this.sub_service.parent_id,
          sub_service_id: this.sub_service.id,
          inventories: this.inventories,
        }).then(() => {
          this.$alert(this.$t("save_ok"));
          this.dialog = false;
          this.$emit("update");
        });
      }
    },
    ...mapActions("inventory", ["getAll"]),

    fetchAll() {
      this.getAll().then((data) => {
        //console.log(data);
        this.items = data.data;
      });
    },
    open() {
      this.dialog = true;
      this.fetchAll();
      this.inventories = this.sub_service.inventories.map((t) => {
        return { inventory_id: t.id, quantity: t.pivot.quantity };
      });
    },
    add() {
      this.inventories.push({});
    },
    del(index) {
      this.inventories.splice(index, 1);
    },
  },
};
</script>
